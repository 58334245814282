import React, { Component } from 'react';
import styled from 'styled-components';
import Default from '../components/Layouts/Default';
import { Button, Title3 } from 'js-components';
import animationData from './animations/404animation.json';

var lottie;

export default class PageNotFoundPage extends Component {
  constructor(props) {
    super(props);
    this.animationIsAttached = false;
  }

  componentDidMount() {
    lottie = require('lottie-web');
    this.attachAnimation();
  }

  attachAnimation() {
    if (this.animationContainer !== undefined && !this.animationIsAttached) {
      const animationProperties = {
        container: this.animationContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationData
      };

      lottie.loadAnimation(animationProperties);
    }
  }

  render() {
    return (
      <Default>
        <FullPageDiv>
          <div
            style={{ width: 400, height: 300 }}
            ref={animationDiv => {
              this.animationContainer = animationDiv;
            }}
          />
          <Title3 mb="22px">The page you’re looking for can’t be found.</Title3>
          <Button onClick={() => (window.location = '/')} size="extraLarge" round type="outline" value="Back to Home" />
        </FullPageDiv>
      </Default>
    );
  }
}

const FullPageDiv = styled.div`
  width: 100vw;
  height: 100vh;
  margin-top: -64px;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
`;
